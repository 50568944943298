<template>
  <v-container
    class="book-a-hotel-container mb-5 pb-5" v-bind:style= "[isMobile ? {'margin-top': '160px'} : isToken ? {'margin-top': '0px'}: {'margin-top': '160px'}]"
  >
    <v-layout column>
      <v-flex xs12 class="book-a-hotel-title py-3">
        <v-flex xs3 class="back-icon pr-3" @click="goBack">
          <img src="@/assets/left.svg" alt="back" />
        </v-flex>
        <v-flex xs7 class="title-text">Book your hotel</v-flex>
      </v-flex>
      <router-view />
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import Vue from 'vue';

const {
  getLoggedInUserDetailService,
} = require('@/services/user/user.service');

export default {
  data() {
    return {
      isMobile: false,
      isToken: false,
    };
  },
  created() {
    this.checkState();
  },
  watch: {
    $route() {
      this.setHeaderAccess();
    },
  },
  beforeRouteLeave(to) {
    const isLoggedIn = this.$cookies.get('isLoggedIn');
    const token = this.$cookies.get('token');
    if (isLoggedIn && token) {
      this.checkGuestUser(to);
    } else {
      window.location.href = to.fullPath;
    }
  },
  methods: {
    ...mapActions({
      setBookingStepStatus: 'setBookingStepStatus',
    }),
    goBack() {
      const { $route } = this;

      if ($route.name === 'payment') {
        this.setBookingStepStatus({ step: 'guests', status: 'pending' });
        this.$router.go(-1);
        return;
      }

      if ($route.name === 'guests') {
        this.setBookingStepStatus({ step: 'confirm', status: 'pending' });
        this.setBookingStepStatus({ step: 'guests', status: 'pending' });
        this.$router.go(-1);
        return;
      }

      this.$router.go(-1);
    },
    async checkState() {
      const payStatus = await localStorage.getItem('bookingStatus');
      if (payStatus === 'true') {
        await localStorage.removeItem('bookingStatus');
        this.$router.push({ path: '/' });
      }
    },
    async checkGuestUser(path) {
      const userDetails = await getLoggedInUserDetailService();
      if (
        userDetails.data.provider === 'Guest'
        && path.name !== 'BookingSuccess'
      ) {
        this.destroyToken();
        window.location.href = path.fullPath;
      } else {
        window.location.href = path.fullPath;
      }
    },
    destroyToken() {
      this.$cookies.remove('token');
      this.$cookies.remove('isLoggedIn');
    },
    setHeaderAccess() {
      this.isMobile = window.innerWidth <= 768;
      const accessToken = Vue.$cookies.get('token');
      if (accessToken) {
        this.isToken = true;
      } else {
        this.isToken = false;
      }
    },
  },
};
</script>

<style lang="scss">
.book-a-hotel-container {
  margin-top: 3.7rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  &.container {
    max-width: 470px !important;
    padding: unset !important;
  }
  .layout {
    height: 100%;
  }

  .book-a-hotel-title {
    display: flex;
    flex-direction: row;
    .back-icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      opacity: 0.35;
      cursor: pointer;
    }
    .title-text {
      font-family: Abel;
      font-size: 32px;
      line-height: 3.143rem;
      color: #080914;
      text-align: left;
      margin: auto 16px;
    }
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .book-a-hotel-container {
    &.container {
      max-width: 100% !important;
    }
    .layout {
      width: 100% !important;
    }
    .book-a-hotel-title {
      .title-text {
        font-size: 20px;
      }
    }
  }
}
</style>
